import { useEffect, useState } from "react";

export default function useNearScreen(
  ref: React.RefObject<HTMLElement>,
  distance: number,
) {
  const [isNearScreen, setIsNearScreen] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsNearScreen(entry.isIntersecting);
      },
      {
        rootMargin: `${distance}px`,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, distance]);

  return isNearScreen;
}
