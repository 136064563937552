export const formatToSoles = (
  number: number | string,
  includePrefix: boolean = true,
) => {
  number = Number(number);

  if (typeof number !== "number" || isNaN(number)) {
    return includePrefix ? "S/ 0.00" : "0.00";
  }

  const formatter = new Intl.NumberFormat("es-PE", {
    style: "currency",
    currency: "PEN",
    currencyDisplay: "symbol",
  });

  const formatterWithoutSymbol = number.toLocaleString("es-PE", {
    minimumFractionDigits: 2,
  });

  return includePrefix ? formatter.format(number) : formatterWithoutSymbol;
};

export const formatToDecimal = (input: any) => {
  let formattedInput = input.replace(/[^0-9.]/g, "");

  let slices = formattedInput.split(".");
  if (slices.length > 2) {
    formattedInput = slices[0] + "." + slices.slice(1).join("");
    slices = formattedInput.split(".");
  }

  if (slices.length === 2) {
    slices[1] = slices[1].substring(0, 2);
  }
  return slices.join(".");
};

export const fixDecimal = (input: string | number) => {
  input = String(input);
  input = parseFloat(input).toFixed(2);
  return Number(input);
};
