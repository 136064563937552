import { createContext } from "react";
import { IService, IServiceState } from "../../interfaces/productAndServices";

export type TServiceContextProps = {
  serviceState: IServiceState;
  setServicesAvaiable: (services: IService[]) => void;
};

export const serviceContext = createContext<TServiceContextProps>(
  {} as TServiceContextProps,
);
