import { createContext } from "react";
import { IProduct, IProductState } from "../../interfaces/productAndServices";

export type ProductsContextProps = {
  productState: IProductState;
  setProductsAvaiable: (products: IProduct[]) => void;
};

export const productContext = createContext<ProductsContextProps>(
  {} as ProductsContextProps,
);
