import fetchTimeout from "../utils/fetchTimeout";
import {
  IClient,
  IDisitrictsRequest,
  IDistrict,
  IDocumentTypesRequest,
} from "../interfaces/cliente";
import { setHeaders, URL_REQUEST } from "./constants";
import { decodeTokenLS } from "../utils/token";

export async function getDocumentList(): Promise<IDocumentTypesRequest> {
  // let response: IDocumentTypesRequest;
  // let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/general/documentoidentidad", {
  //     "headers": setHeaders(),
  //     "body": null,
  //     "method": "GET",
  // });
  // response = await res.json();
  // if (response.status === "200") {
  // return response;
  return {
    status: "200",
    data: [
      {
        vtf_int_idtipodocumento: 1,
        vtf_vch_nombre: "RUC",
      },
      {
        vtf_int_idtipodocumento: 2,
        vtf_vch_nombre: "DNI",
      },
      {
        vtf_int_idtipodocumento: 3,
        vtf_vch_nombre: "CARNÉ DE EXTRANJERIA",
      },
      {
        vtf_int_idtipodocumento: 4,
        vtf_vch_nombre: "PASAPORTE",
      },
    ],
    message: "",
    // }
  };
  // throw new Error("No se pudo obtener la lista de documentos, por favor revise su conexión a internet y recargue la página");
}

// Función auxiliar para convertir el formato de fecha
function convertirFormatoFecha(fechaStr: string): string {
  // Verifica si la fecha tiene el formato dd/mm/yyyy
  const partes = fechaStr.split("/");
  if (partes.length === 3) {
    // Reorganiza las partes para formar yyyy-mm-dd
    return `${partes[2]}-${partes[1]}-${partes[0]}`;
  }
  return fechaStr; // Retorna la fecha original si no tiene el formato esperado
}

export async function getClient(
  documentNumber: string,
  documentType: number,
): Promise<IClient> {
  const decoded = decodeTokenLS();
  if (!decoded?.token) {
    throw new Error(
      "Token no encontrado. Por favor, inicie sesión nuevamente.",
    );
  }

  try {
    const url = new URL(`${URL_REQUEST}/client/findClient`);
    url.searchParams.append("typeDocument", documentType.toString());
    url.searchParams.append("document", documentNumber);
    const response = await fetchTimeout(
      url.toString(),
      {
        headers: {
          ...setHeaders(),
          Authorization: `${decoded.token}`,
        },
        body: null,
        method: "GET",
      },
      10000,
    );

    if (response.status === 401) {
      throw new Error(
        "Sesión expirada o no autorizada. Por favor, vuelva a iniciar sesión",
      );
    }

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`,
      );
    }

    const responseData = await response.json();

    if (responseData.status === "200" && responseData.success) {
      const clientData = responseData.data.clienteResponse;
      return {
        clienteId: clientData.clientId,
        nombre: clientData.name,
        apellido: clientData.lastname,
        fechaNacimiento: convertirFormatoFecha(clientData.birthdate),
        sexo: clientData.gender,
        correo: clientData.email,
        telefono: clientData.phone,
      };
    }

    throw new Error(
      responseData.message || "Error al obtener datos del cliente",
    );
  } catch (error) {
    throw error instanceof Error
      ? error
      : new Error("Error inesperado al consultar el cliente");
  }
}

export async function getDistricts(): Promise<IDistrict[]> {
  let response: IDisitrictsRequest;
  let res: any = await fetchTimeout(
    URL_REQUEST + "/api/v1/ubigeo/buscarDistritos",
    {
      headers: setHeaders(),
      body: null,
      method: "GET",
    },
  );
  response = await res.json();
  if (response.status === "200") {
    return response.data;
  }
  throw new Error(
    "No se pudo obtener la lista de distritos, por favor revise su conexión a internet y recargue la página",
  );
}
