export const getDay = (date?: string) => {
  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const days = [
    "Domingo",
    "Lunes",
    "martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const day = date
    ? new Date(date + " " + new Date().getFullYear())
    : new Date();

  return (
    days[day.getDay()] + ", " + day.getDate() + " de " + months[day.getMonth()]
  );
};
