import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { userContext } from "../../../../context/UserContext/userContext";
import {
  IProduct,
  IService,
  TServiceCard,
} from "../../../../interfaces/productAndServices";
import { violet_1 } from "../../../../components/styled/constant";
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
  StyledInput,
  StyledLabel,
} from "../../../../components/styled/styled";
import { BsPlusLg } from "react-icons/bs";
import { showPopup } from "../../../../utils/showAlert";
import { formatToSoles } from "utils/FormatsHandler";

type Props = {
  show: boolean;
  servicesAvaiable: IService[];
  handleCloseModal: () => void;
  handlerAddSoPModal: (e: IService | IProduct, type: "s" | "p") => void;
  service: any;
  bgColor?: string;
};

export default function AddServiceModal(props: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [servicesFiltered, setServicesFiltered] = useState(
    props.servicesAvaiable,
  );
  const {
    show,
    servicesAvaiable,
    service,
    handleCloseModal,
    handlerAddSoPModal,
    bgColor,
  } = props;
  const { userState } = useContext(userContext);
  const [fadeOut, setFadeOut] = useState(true);
  const [display, setDisplay] = useState(false);
  const { user } = userState;
  const employee = JSON.parse(localStorage.getItem("employee") || "{}");
  const inputEl = useRef<HTMLInputElement>(null);
  type ThisService = {
    service: TServiceCard | undefined;
    quantity: number;
    employeeId: number;
    type: "p" | "s";
    nombreEstado: string;
  };
  const [serviceSelected, setServiceSelected] = useState<
    ThisService | undefined
  >(service);

  const handlerSearch = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>): void => {
      const value = e?.target.value || "";
      setSearch(value);
      setServicesFiltered(
        servicesAvaiable.filter((service) =>
          service.descripcion.toLowerCase().includes(value.toLowerCase()),
        ),
      );
    },
    [servicesAvaiable],
  );

  const handlerSave = useCallback((): void => {
    if (serviceSelected?.service) {
      handlerAddSoPModal(serviceSelected.service, "s");
    }
  }, [serviceSelected]); // eslint-disable-line

  useEffect(() => {
    setServiceSelected({
      type: "s",
      service: service,
    } as ThisService);
  }, [service]);

  const onClose = async () => {
    if (serviceSelected?.service) {
      const res = await showPopup({
        title: "¿Esta seguro que desea salir?",
        message: "Si sale se perderá la información ingresada",
        confirmButtonText: "Si, salir",
        cancelButtonText: "No, volver",
      });
      if (res.isDismissed) {
        return;
      }
      handleCloseModal();
      setServiceSelected(undefined);
    }
    handleCloseModal();
    setServiceSelected(undefined);
  };

  useEffect(() => {
    if (serviceSelected) handlerSearch();
  }, [serviceSelected, handlerSearch, show]);

  useEffect(() => {
    if (show) {
      setFadeOut(!show);
      setDisplay(show);
      setIsOpen(true);
    } else {
      setFadeOut(!show);
      setTimeout(() => {
        setDisplay(show);
        setServiceSelected(undefined);
      }, 300);
    }
    setTimeout(() => {
      if (inputEl.current) inputEl.current.focus();
    }, 300);
  }, [show]);

  const toggling = (): void => {
    setIsOpen(!isOpen);
  };

  const handlerSelect = (amount: number, service: TServiceCard): void => {
    if (service) {
      setIsOpen(false);
    }
    setServiceSelected({
      service: {
        ...service,
      },
      quantity: 1,
      employeeId: 1,
    } as ThisService);
  };

  return (
    <StyledFormGroup fadeOut={fadeOut} show={display}>
      <ModalHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BsPlusLg style={{ cursor: "pointer" }} onClick={onClose}></BsPlusLg>
          <StyledLabel onClick={toggling} color={"purple_1"}>
            {" "}
            {service ? "Editar Servicio" : "Nuevo servicio"}
          </StyledLabel>
        </div>
        <SaveButton onClick={handlerSave} disabled={!serviceSelected?.service}>
          Guardar
        </SaveButton>
      </ModalHeader>
      <StyledContainer style={{ alignItems: "flex-start" }} bgColor={bgColor}>
        <StyledLabel>Servicio</StyledLabel>
        <DropDownContainer
          style={{ pointerEvents: "auto", width: "100%", position: "relative" }}
        >
          <DropDownHeader
            onClick={toggling}
            dropDown={true}
            style={{
              marginBottom: "1rem",
              cursor: "pointer",
            }}
          >
            {serviceSelected?.service?.descripcion || "Seleccione un servicio"}
          </DropDownHeader>

          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                <StyledInput
                  ref={inputEl}
                  style={{ width: "-webkit-fill-available" }}
                  onFocus={(e) => {
                    handlerSearch(e);
                  }}
                  onChange={handlerSearch}
                  value={search}
                  placeholder="Buscar servicio"
                ></StyledInput>
                {servicesFiltered.map((item) => (
                  <ListItem
                    onClick={() => {
                      handlerSearch();
                      handlerSelect(1, {
                        ...item,
                        empleadoAtencion: user?.nombre + " " + user?.apellido,
                      });
                    }}
                    key={Math.random()}
                  >
                    {item.descripcion} - {formatToSoles(item.precio)}
                  </ListItem>
                ))}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
        <StyledLabel>Estilista</StyledLabel>
        <StyledInput
          disabled
          style={{ width: "-webkit-fill-available", fontWeight: 500 }}
          value={employee?.employeeName}
        ></StyledInput>
      </StyledContainer>
    </StyledFormGroup>
  );
}

const fadeIn = keyframes`
    from {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100vh);
    }
`;
const StyledFormGroup = styled("div").attrs(
  (props: { fadeOut: boolean; show: boolean }) => ({
    fadeOut: props.fadeOut,
    show: props.show,
  }),
)`
  margin-bottom: 1rem;
  flex-direction: column;
  align-items: center;
  width: -webkit-fill-available;
  position: absolute;
  height: 100vh;
  width: 100vw;
  transform: translateY(-100vh);
  animation: ${(props: { fadeOut: boolean }) =>
      props.fadeOut ? fadeOut : fadeIn}
    0.5s ease-in-out;
  animation-fill-mode: forwards;
  display: ${(props: { show: boolean }) => (props.show ? "flex" : "none")};
  z-index: 500;
  background-color: white;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const StyledContainer = styled.div.attrs((props: { bgColor?: string }) => ({
  bgColor: props.bgColor,
}))`
  display: flex;
  background-color: ${(props) => props.bgColor || "none"};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5%;
  width: -webkit-fill-available;
`;

const ModalHeader = styled.div`
  margin-top: 1rem;
  color: ${violet_1};
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5%;
  border-radius: 0.5rem;
  width: -webkit-fill-available;
  font-size: 1rem;
  font-weight: bold;
  justify-content: space-between;
  & label {
    color: ${violet_1};
    margin-left: 1rem;
    text-decoration-line: underline;
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 0.2rem;
  }
  & svg {
    font-size: 1.5rem;
    font-weight: bold;
    transform: rotate(45deg);
    transition: all 0.2s ease-in-out;
    &:hover {
      color: red;
    }
    & path {
      stroke: ${violet_1};
    }
  }
`;

const SaveButton = styled.button`
  background-color: #937cf4;
  color: white;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #937cf4;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #937cf4;
  }
`;
