import { createContext } from "react";
import { IUser, IUserState } from "../../interfaces/user";

export type UserContextProps = {
  userState: IUserState;
  setUser: (user: IUser | undefined) => void;
  setToken: (token: string | undefined) => void;
};

export const userContext = createContext<UserContextProps>(
  {} as UserContextProps,
);
