import { useCallback, useContext, useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { userContext } from "../../../../context/UserContext/userContext";
import {
  IProduct,
  TProductCard,
} from "../../../../interfaces/productAndServices";
import { violet_1 } from "../../../../components/styled/constant";
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
  StyledInput,
  StyledLabel,
} from "../../../../components/styled/styled";
import { BsPlusLg } from "react-icons/bs";
import { showPopup } from "../../../../utils/showAlert";
import { formatToSoles } from "utils/FormatsHandler";

type Props = {
  show: boolean;
  productsAvailable: IProduct[];
  handleCloseModal: () => void;
  handlerAddSoPModal: (e: IProduct | IProduct, type: "s" | "p") => void;
  product: any;
  bgColor?: string;
};

export default function AddProductModal(props: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [ProductsFiltered, setProductsFiltered] = useState(
    props.productsAvailable,
  );
  const {
    show,
    productsAvailable,
    product,
    handleCloseModal,
    handlerAddSoPModal,
    bgColor,
  } = props;
  const { userState } = useContext(userContext);
  const [fadeOut, setFadeOut] = useState(true);
  const [display, setDisplay] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);
  const { user } = userState;
  type ThisProduct = {
    service: TProductCard | undefined;
    quantity: number;
    employeeId: number;
    type: "p" | "s";
    nombreEstado: string;
  };
  const [productSelected, setProductSelected] = useState<
    ThisProduct | undefined
  >(product);

  const handlerSearch = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>): void => {
      const value = e?.target.value || "";
      setSearch(value);
      setProductsFiltered(
        productsAvailable.filter((product) =>
          product.descripcion.toLowerCase().includes(value.toLowerCase()),
        ),
      );
    },
    [productsAvailable],
  );

  const handlerSave = useCallback((): void => {
    if (productSelected?.service) {
      handlerAddSoPModal(productSelected.service, "p");
    }
  }, [productSelected]); // eslint-disable-line

  useEffect(() => {
    setProductSelected({
      type: "s",
      service: product,
    } as ThisProduct);
  }, [product]);

  const onClose = async () => {
    if (productSelected?.service) {
      const res = await showPopup({
        title: "¿Esta seguro que desea salir?",
        message: "Si sale se perderá la información ingresada",
        confirmButtonText: "Si, salir",
        cancelButtonText: "No, volver",
      });
      if (res.isDismissed) {
        return;
      }
      handleCloseModal();
      setProductSelected(undefined);
    }
    handleCloseModal();
    setProductSelected(undefined);
  };

  useEffect(() => {
    if (productSelected) handlerSearch();
  }, [productSelected, handlerSearch, show]);

  useEffect(() => {
    if (show) {
      setFadeOut(!show);
      setDisplay(show);
      setIsOpen(true);
    } else {
      setFadeOut(!show);
      setTimeout(() => {
        setDisplay(show);
        setProductSelected(undefined);
      }, 300);
    }
    setTimeout(() => {
      if (inputEl.current) inputEl.current.focus();
    }, 300);
  }, [show]);

  const toggling = (): void => {
    setIsOpen(!isOpen);
  };

  const handlerSelect = (amount: number, product: TProductCard): void => {
    if (product) {
      setIsOpen(false);
    }
    setProductSelected({
      service: {
        ...product,
      },
      quantity: 1,
      employeeId: 1,
    } as ThisProduct);
  };

  const employee = JSON.parse(localStorage.getItem("employee") || "{}");
  return (
    <StyledFormGroup fadeOut={fadeOut} show={display}>
      <ModalHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BsPlusLg style={{ cursor: "pointer" }} onClick={onClose}></BsPlusLg>
          <StyledLabel onClick={toggling} color={"purple_1"}>
            {" "}
            {product ? "Editar producto" : "Nuevo Producto"}
          </StyledLabel>
        </div>
        <SaveButton onClick={handlerSave} disabled={!productSelected?.service}>
          Guardar
        </SaveButton>
      </ModalHeader>
      <StyledContainer style={{ alignItems: "flex-start" }} bgColor={bgColor}>
        <StyledLabel>Producto</StyledLabel>
        <DropDownContainer
          style={{ pointerEvents: "auto", width: "100%", position: "relative" }}
        >
          <DropDownHeader
            onClick={toggling}
            dropDown={true}
            style={{
              marginBottom: "1rem",
              cursor: "pointer",
            }}
          >
            {productSelected?.service?.descripcion || "Seleccione un Producto"}
          </DropDownHeader>

          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                <StyledInput
                  ref={inputEl}
                  style={{ width: "-webkit-fill-available" }}
                  onFocus={(e) => {
                    handlerSearch(e);
                  }}
                  onChange={handlerSearch}
                  value={search}
                  placeholder="Buscar Producto"
                ></StyledInput>
                {ProductsFiltered.map((item) => (
                  <ListItem
                    onClick={() => {
                      handlerSearch();
                      handlerSelect(productSelected?.quantity || 1, {
                        ...item,
                        empleadoAtencion: user?.nombre + " " + user?.apellido,
                      });
                    }}
                    key={Math.random()}
                  >
                    {item.descripcion} - {formatToSoles(item.precio)}
                  </ListItem>
                ))}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
        <StyledLabel>Estilista</StyledLabel>
        <StyledInput
          disabled
          style={{ width: "-webkit-fill-available", fontWeight: 500 }}
          value={employee?.employeeName || user?.nombre + " " + user?.apellido}
        ></StyledInput>
      </StyledContainer>
    </StyledFormGroup>
  );
}

const fadeIn = keyframes`
    from {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100vh);
    }
`;

const StyledFormGroup = styled("div").attrs(
  (props: { fadeOut: boolean; show: boolean }) => ({
    fadeOut: props.fadeOut,
    show: props.show,
  }),
)`
  margin-bottom: 1rem;
  flex-direction: column;
  align-items: center;
  width: -webkit-fill-available;
  position: absolute;
  height: 100vh;
  width: 100vw;
  transform: translateY(-100vh);
  animation: ${(props: { fadeOut: boolean }) =>
      props.fadeOut ? fadeOut : fadeIn}
    0.5s ease-in-out;
  animation-fill-mode: forwards;
  display: ${(props: { show: boolean }) => (props.show ? "flex" : "none")};
  z-index: 500;
  background-color: white;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const StyledContainer = styled.div.attrs((props: { bgColor?: string }) => ({
  bgColor: props.bgColor,
}))`
  display: flex;
  background-color: ${(props) => props.bgColor || "none"};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5%;
  width: -webkit-fill-available;
`;

const ModalHeader = styled.div`
  margin-top: 1rem;
  color: ${violet_1};
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5%;
  border-radius: 0.5rem;
  width: -webkit-fill-available;
  font-size: 1rem;
  font-weight: bold;
  justify-content: space-between;
  & label {
    color: ${violet_1};
    margin-left: 1rem;
    text-decoration-line: underline;
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 0.2rem;
  }
  & svg {
    font-size: 1.5rem;
    font-weight: bold;
    transform: rotate(45deg);
    transition: all 0.2s ease-in-out;
    &:hover {
      color: red;
    }
    & path {
      stroke: ${violet_1};
    }
  }
`;

const SaveButton = styled.button`
  background-color: #937cf4;
  color: white;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #937cf4;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #937cf4;
  }
`;
