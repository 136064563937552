import { useReducer } from "react";
import { searchContext } from "./SearchContext";
import { searchReducer } from "./SearchReducer";

interface propos {
  children: JSX.Element | JSX.Element[];
}

const INITIAL_STATE: { search: string } = {
  search: "",
};

export const SearchProvider = ({ children }: propos): any => {
  const [searchState, dispatch] = useReducer(searchReducer, INITIAL_STATE);

  const setSearch = (search: string) => {
    dispatch({
      type: "SET_SEARCH",
      payload: search,
    });
  };

  return (
    <searchContext.Provider
      value={{
        searchState,
        setSearch,
      }}
    >
      {children}
    </searchContext.Provider>
  );
};
