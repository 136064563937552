export default function fetchTimeout(
  url: string,
  options: RequestInit,
  timeout = 7000,
): Promise<any> {
  return Promise.race([
    fetch(url, options),
    // new Promise((_, reject) =>
    //     setTimeout(() => reject(new Error('timeout')), timeout)
    // )
  ]);
}
