import { decodeToken } from "react-jwt";
import { IUser } from "../interfaces/user";

type decodedToken = {
  user: IUser;
  token: string;
  exp: number;
};

export function decodeTokenLS(): decodedToken | undefined {
  const token = localStorage.getItem("token");
  // TODO: Temporal
  if (token) {
    const decode: any = decodeToken(token);

    const { group, exp } = decode;

    const user: IUser = {
      empleadoId: group.employeeId,
      empresaId: group.companyId,
      localId: group.localId,
      nombreLocal: group.localName,
      nombre: group.employeeName,
      apellido: "",
      email: "",
      tipoUsuario: 3,
      admin: true,
      crearComanda: true,
      tipoPrivacidadDatosCliente: 1,
    };

    return { user, exp, token };
  }
  return undefined;
}
// "data": {
//     "empleadoId": 3557,
//     "empresaId": 16,
//     "localId": 25,
//     "nombreLocal": "GAIA SALON & SPA",
//     "nombre": "ADRIANA VALERIA",
//     "apellido": "CORDOVA SILVESTRE",
//     "email": "adriana.cordova@lilab.pe",
//     "tipoUsuario": 3,
//     "admin": true,
//     "crearComanda": true,
//     "tipoPrivacidadDatosCliente": 1
//   }
