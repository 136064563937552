import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import { productContext } from "../../../../context/products/ProductsContext";
import { serviceContext } from "../../../../context/services/serviceContext";
import { IDocumentTypes } from "../../../../interfaces/cliente";
import { getClient, getDocumentList } from "../../../../request/client";
import {
  getProductsAvaiable,
  getServicesAvaiable,
} from "../../../../request/productAndServices";
import { showPopup } from "../../../../utils/showAlert";
import { TInputClient, TErrorsClient } from "../addComanda";
import { validate } from "../../../../utils/validate";
import { showLoader } from "../../../../utils/loader";
import { userContext } from "../../../../context/UserContext/userContext";
import SelectInput from "../../../../components/selectInput/SelectInput";
import TextInput from "../../../../components/textInput/TextInput";
import DateInput from "../../../../components/dateInput/DateInput";

type TProps = {
  input: TInputClient;
  setInput: React.Dispatch<React.SetStateAction<TInputClient>>;
  documentType: string;
  setDocumentType: React.Dispatch<React.SetStateAction<string>>;
  documentList: IDocumentTypes[];
  setDocumentList: React.Dispatch<React.SetStateAction<IDocumentTypes[]>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  formErrors: TErrorsClient;
  setFormErrors: React.Dispatch<React.SetStateAction<TErrorsClient>>;
};

const ClientData = ({
  input,
  setInput,
  documentType,
  setDocumentType,
  documentList,
  setDocumentList,
  isLoading,
  setIsLoading,
  formErrors,
  setFormErrors,
}: TProps) => {
  const { productState, setProductsAvaiable } = useContext(productContext);
  const { productsAvaiable } = productState;
  const { serviceState, setServicesAvaiable } = useContext(serviceContext);
  const { servicesAvaiable } = serviceState;
  const {
    userState: { user },
  } = useContext(userContext);

  const [isSearch, setIsSearch] = useState(false);

  const [typeInput, setTypeInput] = useState("number");

  const genders = [
    { value: 1, name: "Masculino" },
    { value: 2, name: "Femenino" },
  ];

  const documentOptions = documentList.map((doc) => ({
    value: doc.vtf_vch_nombre,
    name: doc.vtf_vch_nombre,
  }));

  const handleDocumentTypeChange = (e: any) => {
    const value = e.target.value;
    setDocumentType(value);
    cleanForm(true);
    setTypeInput(value === "DNI" || value === "RUC" ? "number" : "text");
  };

  const cleanForm = (cleanAll: boolean) => {
    setInput({
      ...input,
      docNumber: cleanAll ? "" : input.docNumber,
      clientId: null,
      name: "",
      lastName: "",
      email: "",
      phone: "",
      gender: 0,
      birth: "",
    });

    setFormErrors({
      docNumber: cleanAll ? "" : formErrors.docNumber,
      email: "",
      phone: "",
      name: "",
      lastName: "",
      birth: "",
      gender: "",
    });
  };

  const handlerGetClient = async () => {
    setIsSearch(true);
    const docIdType = documentList.find(
      (doc) => doc.vtf_vch_nombre === documentType,
    );
    if (!docIdType) {
      return showPopup({
        message: "Error al obtener el tipo de documento",
        icon: "error",
      });
    }
    try {
      if (input.docNumber.length > 7) {
        const res = await getClient(
          input.docNumber,
          docIdType.vtf_int_idtipodocumento,
        );
        setInput({
          name: res.nombre.capitalizeFirstLetterAfter(" "),
          email: res.correo,
          phone: res.telefono,
          docNumber: input.docNumber,
          gender: res.sexo,
          lastName: res.apellido?.capitalizeFirstLetterAfter(" "),
          birth: res.fechaNacimiento,
          clientId: res.clienteId,
        });

        setFormErrors({
          ...formErrors,
          email: "",
          phone: "",
          name: "",
          lastName: "",
          birth: "",
          gender: "",
        });
      } else {
        return false;
      }
    } catch (err: any) {
      cleanForm(false);
      return showPopup({
        message: "Error al obtener el cliente",
        icon: "error",
      });
    } finally {
      setIsSearch(false);
    }
  };
  const initialCharge = useCallback(async () => {
    try {
      const docs = await getDocumentList();
      if (servicesAvaiable.length > 0)
        if (servicesAvaiable[0].descripcion === "No listado") {
          const services = await getServicesAvaiable();
          setServicesAvaiable(services);
        }
      if (productsAvaiable.length > 0)
        if (productsAvaiable[0].descripcion === "No listado") {
          const products = await getProductsAvaiable();
          setProductsAvaiable(products);
        }
      setDocumentList(docs.data);
    } catch (err: any) {
      setTimeout(() => {
        // se envia con retraso porque sino se cierra el modal
        showPopup({
          message: "Recargar Página",
          icon: "error",
        }).then(() => {
          window.location.reload();
        });
        console.log(err);
      }, 500);
    } finally {
      setIsLoading(false);
    }
  }, [setProductsAvaiable, setServicesAvaiable]); // eslint-disable-line

  useEffect(() => {
    setIsLoading(true);
    initialCharge();
  }, [initialCharge, setIsLoading]);

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      Swal.close();
    }
  }, [isLoading]);

  const handlerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    if (name === "name") {
      value = value.capitalizeFirstLetterAfter(" ");
    }
    if (name === "lastName") {
      value = value.capitalizeFirstLetterAfter(" ");
    }

    if (name === "phone") {
      if (value.length > 9) return false;
    }

    if (name === "docNumber") {
      if (documentType === "RUC" && value.length > 11) return false;
      if (documentType === "DNI" && value.length > 8) return false;
      if (
        (documentType === "CARNÉ DE EXTRANJERIA" ||
          documentType === "PASAPORTE") &&
        value.length > 12
      )
        return false;
    }

    setFormErrors({
      ...formErrors,
      [e.target.name]: validate(e.target.value, e.target.name, documentType),
    });

    setInput({
      ...input,
      [name]: value,
    });
  };

  return (
    <>
      <StyledFormGroup>
        <SelectInput
          label="Tipo de documento"
          value={documentType}
          handleChange={handleDocumentTypeChange}
          items={documentOptions}
          width="100%"
          placeholder="Tipo Doc."
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <TextInput
          placeholder="Ingrese número de documento"
          label="Número de documento"
          name="docNumber"
          value={input.docNumber}
          handleChange={handlerInput}
          handleBlur={handlerGetClient}
          disabled={isSearch}
          error={formErrors.docNumber}
          touched={!!formErrors.docNumber}
          keyboardType={typeInput}
          width="100%"
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <TextInput
          placeholder="Ingrese nombre"
          label="Nombre"
          name="name"
          value={input.name || ""}
          handleChange={handlerInput}
          disabled={isSearch}
          error={formErrors.name}
          touched={!!formErrors.name}
          width="100%"
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <TextInput
          placeholder="Ingrese apellido"
          label="Apellido"
          name="lastName"
          value={input.lastName || ""}
          handleChange={handlerInput}
          disabled={isSearch}
          error={formErrors.lastName}
          touched={!!formErrors.lastName}
          width="100%"
        />
      </StyledFormGroup>

      {user?.tipoPrivacidadDatosCliente !== 3 || user?.tipoUsuario !== 2 ? (
        <>
          <StyledFormGroup>
            <TextInput
              placeholder="Ingrese correo"
              label="Correo"
              name="email"
              value={input.email || ""}
              handleChange={handlerInput}
              disabled={isSearch}
              error={formErrors.email}
              touched={!!formErrors.email}
              width="100%"
            />
          </StyledFormGroup>

          <StyledFormGroup>
            <TextInput
              placeholder="Ingrese celular"
              label="Celular"
              name="phone"
              value={input.phone || ""}
              handleChange={handlerInput}
              disabled={isSearch}
              error={formErrors.phone}
              touched={!!formErrors.phone}
              keyboardType="number"
              maxLength={9}
              width="100%"
            />
          </StyledFormGroup>

          <StyledFormGroup>
            <DateInput
              label="Fecha de nacimiento"
              disabled={isSearch}
              error={formErrors.birth}
              touched={!!formErrors.birth}
              required={false}
              containerStyle={{}}
              inputStyle={{}}
              inputProps={{
                name: "birth",
                value: input.birth || "",
                onChange: handlerInput,
                placeholder: "Ingrese fecha de nacimiento",
              }}
            />
          </StyledFormGroup>

          <StyledFormGroup>
            <SelectInput
              label="Sexo"
              value={input.gender.toString()}
              handleChange={(e: any) => {
                setInput({ ...input, gender: Number(e.target.value) });
              }}
              items={genders}
              width="100%"
              placeholder="Seleccionar género"
            />
          </StyledFormGroup>
        </>
      ) : null}
    </>
  );
};

export default ClientData;

const StyledFormGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  align-items: center;
  position: relative;
  gap: 1rem;
  width: 100%;
`;
