import {
  IProduct,
  IProductRequest,
  IService,
  IServiceRequest,
} from "../interfaces/productAndServices";
import fetchTimeout from "../utils/fetchTimeout";
import { decodeTokenLS } from "../utils/token";
import { setHeaders, URL_REQUEST } from "./constants";

export async function getProductsAvaiable(): Promise<IProduct[]> {
  let response: IProductRequest;
  const decoded = decodeTokenLS();
  const employee = JSON.parse(localStorage.getItem("employee") || "{}");

  if (decoded) {
    // Realizamos la petición a la nueva ruta
    let res: any = await fetchTimeout(URL_REQUEST + "/item/products", {
      headers: {
        ...setHeaders(),
        Authorization: `${decoded.token}`,
      },
      body: null,
      method: "GET",
    });
    response = await res.json();

    if (response.status === "200") {
      // Adaptamos la estructura de los productos al formato requerido
      const adaptedProducts = response.data.products.map((product: any) => ({
        productoEmpleadoId: product.vtf_int_idlocalproducto,
        productoId: product.vtf_int_idlocalproducto,
        descripcion: product.vtf_vch_codigo
          ? `${product.vtf_vch_codigo} - ${product.vtf_vch_descripcion}`
          : product.vtf_vch_descripcion,
        codigo: product.vtf_vch_codigo || "",
        precio: parseFloat(product.vtf_dec_precio),
        vtf_int_modalidadcomisionproducto: 0,
        vtf_dec_valorcomisionproducto: 0,
        vtf_int_idempleado: employee?.employeeId,
      }));

      return adaptedProducts;
    }
    throw new Error(response.message);
  }
  throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}

export async function getServicesAvaiable(): Promise<IService[]> {
  let response: IServiceRequest;
  const decoded = decodeTokenLS();
  if (decoded) {
    let res: any = await fetchTimeout(URL_REQUEST + "/item/services", {
      headers: {
        ...setHeaders(),
        Authorization: `${decoded.token}`,
      },
      body: null,
      method: "GET",
    });
    response = await res.json();

    if (response.status === "200") {
      // Adaptamos la estructura de los servicios al formato requerido
      const adaptedServices = response.data.services.map((service: any) => ({
        servicioEmpleadoId: service.PriceServiceId,
        servicioId: service.PriceServiceId,
        descripcion: `${service.serviceName} - ${service.PriceServiceName}`,
        precio: parseFloat(service.Price),
        categoria1: null,
        categoria2: null,
        categoria3: null,
        tiempoEstimadoMinutos: service.Duration,
      }));

      return adaptedServices;
    }
    throw new Error(response.message);
  }
  throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}
