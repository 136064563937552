/* eslint-disable no-extend-native */
import mixpanel from "mixpanel-browser";
declare global {
  interface String {
    capitalize(): string;
    capitalizeFirstLetterAfter(afterLetter: string): string;
  }
  interface Window {
    mixpanel: any;
  }
}

window.mixpanel = mixpanel;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function addToPrototype() {
  String.prototype.capitalize = function () {
    // eslint-disable-line
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
  String.prototype.capitalizeFirstLetterAfter = function (afterLetter: string) {
    // eslint-disable-line
    let aux = "";
    this.split(afterLetter).forEach((word) => {
      aux += word.toLocaleLowerCase().capitalize();

      aux += afterLetter;
    });
    aux = aux.substring(0, aux.length - 1);

    return aux;
  };
}
