type ComandaAction = { type: "SET_SEARCH"; payload: string };

export const searchReducer = (
  state: { search: string },
  action: ComandaAction,
): { search: string } => {
  switch (action.type) {
    case "SET_SEARCH":
      return { ...state, search: action.payload };
    default:
      return state;
  }
};
