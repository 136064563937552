import { createContext } from "react";

export type SearchContextProps = {
  searchState: { search: string };
  setSearch: (search: string) => void;
};

export const searchContext = createContext<SearchContextProps>(
  {} as SearchContextProps,
);
