import { IUser, IUserState } from "../../interfaces/user";

type UserAction =
  | { type: "LOGIN"; payload: IUser | undefined }
  | { type: "LOGOUT" }
  | { type: "ERROR"; payload: string | undefined }
  | { type: "SET_TOKEN"; payload: string | undefined };

export const userReducer = (
  state: IUserState,
  action: UserAction,
): IUserState => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: undefined };
    case "ERROR":
      return { ...state, user: undefined };
    case "SET_TOKEN":
      return { ...state, token: action.payload };
    default:
      return state;
  }
};
