import React, { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import styled, { keyframes } from "styled-components";
import { violet_1 } from "../../../components/styled/constant";
// import leftArrow from '../../../assets/circle-arrow-purple-left.svg';
// import rightArrow from '../../../assets/circle-arrow-purple-right.svg';

const fadeIn = keyframes`
    from {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100vh);
    }
`;

const StyledFormGroup = styled("div").attrs(
  (props: { fadeOut: boolean; show: boolean }) => ({
    fadeOut: props.fadeOut,
    show: props.show,
  }),
)`
  margin-bottom: 1rem;
  flex-direction: column;
  align-items: center;
  width: -webkit-fill-available;
  position: absolute;
  height: 100vh;
  width: 100vw;
  transform: translateY(-100vh);
  animation: ${(props: { fadeOut: boolean }) =>
      props.fadeOut ? fadeOut : fadeIn}
    0.5s ease-in-out;
  animation-fill-mode: forwards;
  display: ${(props: { show: boolean }) => (props.show ? "flex" : "none")};
  z-index: 500;
  background-color: white;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const ModalHeader = styled.div`
  margin-top: 1rem;
  color: ${violet_1};
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5%;
  border-radius: 0.5rem;
  width: -webkit-fill-available;
  font-size: 1rem;
  font-weight: bold;
  justify-content: space-between;
  & label {
    color: ${violet_1};
    margin-left: 1rem;
    text-decoration-line: underline;
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 0.2rem;
  }
  & svg {
    font-size: 1.5rem;
    font-weight: bold;
    transform: rotate(45deg);
  }
`;

type Props = {
  show: boolean;
  bgColor?: string;
  setShowModal: (any: any) => void;
  fadeOut: boolean;
  setStartDate: (any: any) => void;
  //setEndDate: (any: any) => void
};

const ModalDatePicker = (props: Props): JSX.Element => {
  const today = new Date();
  const { show, setShowModal, setStartDate } = props;
  const [fadeOut, setFadeOut] = useState(props.fadeOut);
  const [firstDateInput, setFirstDateInput] = useState(
    today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2),
  );
  // const [selectedDate, setSelectedDate] = useState({day: '', month: '', year: ''});
  // const [currentDate, setCurentDate] = useState({day: '', month: '', year: ''});

  return (
    <>
      <StyledFormGroup fadeOut={fadeOut} show={show}>
        <ModalHeader>
          <div style={{ display: "flex", alignItems: "center" }}>
            <BsPlusLg
              style={{ cursor: "pointer" }}
              onClick={() => {
                setFadeOut(true);
                setTimeout(() => {
                  setFadeOut(false);
                  setShowModal(false);
                }, 500);
              }}
            />
          </div>
        </ModalHeader>
        <span style={{ fontSize: "16px" }}>
          Selecciona la fecha que quieres consultar
        </span>
        {/* <div 
                    style={{
                        width: '90%',
                        border: '1px solid #937CF4',
                        boxShadow: '4px 4px 4px 1px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        height: '320px',
                        marginTop: '20px'
                    }}
                >
                    <div>
                        <img src={leftArrow} alt='back'/>
                        <span>{selectedDate.month}</span>
                        <img src={rightArrow} alt='forward'/>
                    </div>
                </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <input
            type="date"
            value={firstDateInput}
            onChange={(e: any) => setFirstDateInput(e.target.value.toString())}
            style={{
              color: "black",
              border: "1px solid black",
              padding: "5px",
            }}
          />
          <button
            type="button"
            onClick={() => {
              setStartDate(firstDateInput);
              setShowModal(false);
            }}
            style={{
              width: "300px",
              height: "40px",
              color: "white",
              backgroundColor: "#45348E",
              outline: "none",
              fontSize: "20px",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Continuar
          </button>
        </div>
      </StyledFormGroup>
    </>
  );
};

export default ModalDatePicker;
