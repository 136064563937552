import { useReducer } from "react";
import { productContext } from "./ProductsContext";
import { IProduct, IProductState } from "../../interfaces/productAndServices";
import { productReducer } from "./ProductsReducer";

interface props {
  children: JSX.Element | JSX.Element[];
}

const INITIAL_STATE: IProductState = {
  productsAvaiable: [
    {
      codigo: "",
      descripcion: "No listado",
      precio: 0,
      productoEmpleadoId: 0,
      productoId: 0,
      vtf_dec_valorcomisionproducto: 0,
      vtf_int_idempleado: 0,
      vtf_int_modalidadcomisionproducto: 0,
    },
  ],
};

export const ProductProvider = ({ children }: props) => {
  const [productState, dispatch] = useReducer(productReducer, INITIAL_STATE);

  const setProductsAvaiable = (products: IProduct[]) => {
    dispatch({
      type: "SET_PRODUCTS_AVAIABLE",
      payload: products,
    });
  };

  return (
    <productContext.Provider
      value={{
        productState,
        setProductsAvaiable,
      }}
    >
      {children}
    </productContext.Provider>
  );
};
