/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsFillStopCircleFill } from "react-icons/bs";
import { IoRefreshCircle } from "react-icons/io5";
import styled from "styled-components";
import Swal from "sweetalert2";
import { IServiceQueue } from "../../interfaces/service-queue";
import {
  getServiceQueue,
  updateServiceState,
} from "../../request/service-queue";
import { showPopup } from "../../utils/showAlert";
import Peace from "../comandas/groups/peace";
import Loader from "../../components/elements/loader/loader";
import { purple_1 } from "../../components/styled/constant";
import {
  BodyContainer,
  Main,
  StyledColorContainer,
} from "../../components/styled/styled";
import {
  socketInit,
  socket,
  refreshComandas,
  deleteRefreshComandas,
} from "../../utils/socket";
import useMixpanel from "hooks/useMixpanel/useMixpanel";

export default function ServiceQueue() {
  const { track } = useMixpanel();
  const [timer, setTimer] = useState<{
    secons: number;
    minutes: number;
    hours: number;
  }>({
    secons: 0,
    minutes: 0,
    hours: 0,
  });
  const [queue, setQueue] = useState<{
    inProgress: IServiceQueue[];
    pending: IServiceQueue[];
  }>({ inProgress: [], pending: [] });

  const [loading, setLoading] = useState(false);

  const getTime = (service: IServiceQueue): string => {
    const localDate = new Date();
    const dateNow = new Date(
      localDate.getTime() + localDate.getTimezoneOffset() * 60000,
    );
    dateNow.setHours(dateNow.getHours() - 5);

    const [handlerDate, hours] = service.fechaInicio.split(" ");
    const [day, month, year] = handlerDate.split("/");
    const [hour, minute, second] = hours.split(":");
    const date = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hour),
      parseInt(minute),
      parseInt(second),
    );

    const diff = dateNow.getTime() - date.getTime();

    const time = {
      hours: Math.floor(diff / 1000 / 60 / 60),
      minutes: Math.floor((diff / 1000 / 60) % 60),
      secons: Math.floor((diff / 1000) % 60),
    };
    const seconsFormat = time.secons < 10 ? "0" + time.secons : time.secons;
    const minutesFormat = time.minutes < 10 ? "0" + time.minutes : time.minutes;
    const hourFormat = time.hours < 10 ? "0" + time.hours : time.hours;

    return hourFormat + " : " + minutesFormat + " : " + seconsFormat;
  };
  const initQueue = useCallback(async (refresh: boolean) => {
    if (refresh === false) {
      setLoading(true);
    }
    try {
      const res: IServiceQueue[] = await getServiceQueue();
      const handlerInProgress = res
        .filter((item) => item.nombreEstado === "SERVICIO INICIADO")
        .map((item) => ({ ...item }));
      const handlerPending = res.filter(
        (item) => item.nombreEstado === "EN ESPERA",
      );

      setQueue({ inProgress: handlerInProgress, pending: handlerPending });
    } catch (err: any) {
      console.error(err);
      if (refresh === false) {
        showPopup({
          message: err || "Error al obtener la cola de servicio",
          icon: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    initQueue(false);
  }, [initQueue]);

  useEffect(() => {
    socketInit();
    socket.on("vtf_comanda_creacion", (mensaje) => {
      console.log("Creacion servicio");
      const handler = refreshComandas(mensaje.comanda);
      if (handler) {
        initQueue(true);
      }
    });

    socket.on("vtf_comanda_actualizacion", (mensaje) => {
      console.log("Edicion de Comanda servicio");
      const handler = refreshComandas(mensaje.comanda);
      if (handler) {
        initQueue(true);
      }
    });

    socket.on("vtf_comanda_eliminacion", ({ comandaId, empleadosId }) => {
      console.log("Eliminacion servicio", comandaId);
      const handler = deleteRefreshComandas(empleadosId);
      if (handler !== undefined) {
        initQueue(true);
      }
    });
  }, []);

  const updateTimmer = useCallback(({ secons, minutes, hours }) => {
    if (secons === 59) {
      if (minutes === 59) {
        setTimer({
          secons: 0,
          minutes: 0,
          hours: hours + 1,
        });
      } else {
        setTimer({
          secons: 0,
          minutes: minutes + 1,
          hours: hours,
        });
      }
    } else {
      setTimer({
        secons: secons + 1,
        minutes: minutes,
        hours: hours,
      });
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTimmer({
        secons: timer.secons,
        minutes: timer.minutes,
        hours: timer.hours,
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timer, updateTimmer]);

  const changeStatus = async (status: number, id: number) => {
    track(status === 1 ? "ReiniciarS" : status === 2 ? "IniciarS" : "DetenerS");

    type MessageType = {
      [key: number]: {
        title: string;
        message: string;
        confirmText: string;
      };
    };

    const messages: MessageType = {
      1: {
        title: "¿Desea regresar el servicio?",
        message: "El servicio volverá a la cola de espera",
        confirmText: "Si, regresar el servicio",
      },
      2: {
        title: "¿Deseas iniciar el servicio?",
        message: "",
        confirmText: "Si, iniciar el servicio",
      },
      3: {
        title: "¿Estás seguro de finalizar este servicio?",
        message: "Esta acción no se puede deshacer",
        confirmText: "Si, finalizar el servicio",
      },
    };

    const currentMessage = messages[status];

    const res = await showPopup({
      title: currentMessage.title,
      message: currentMessage.message,
      confirmButtonText: currentMessage.confirmText,
      cancelButtonText: "No",
    });

    if (!res.isConfirmed) {
      track(
        status === 1
          ? "cancelarReiniciarS"
          : status === 2
            ? "cancelarIniciarS"
            : "cancelarDetenerS",
      );
      return;
    }

    track(
      status === 1
        ? "siReiniciarS"
        : status === 2
          ? "siIniciarS"
          : "siDetenerS",
    );

    try {
      const res = await updateServiceState(status, id);
      if (res) {
        initQueue(false);
      }
    } catch (err: any) {
      console.error(err);
      Swal.fire({
        title: "Error",
        text: err,
        icon: "error",
      });
    }
  };

  return (
    <BodyContainer>
      <Main>
        {loading ? (
          <Loader />
        ) : (
          <>
            {queue.inProgress.length
              ? queue.inProgress.map((item, index) => (
                  <ColorContainer key={index}>
                    <StyledTitleContainer>
                      <div>
                        <StyledTitle>{item.servicio}</StyledTitle>
                        <StyledSubtitle>{item.clienteNombre}</StyledSubtitle>
                      </div>
                      <TimmerAndIconsContainer>
                        <IconsContainer>
                          <IoRefreshCircle
                            size={"2.4rem"}
                            style={{ transform: "matrix(-1, 0, 0, 1, 0, 0)" }}
                            onClick={() => changeStatus(1, item.detalleId)}
                          />
                          <BsFillStopCircleFill
                            style={{
                              marginTop: "0.15rem",
                              marginLeft: "0.55rem",
                            }}
                            size={"2rem"}
                            onClick={() => changeStatus(3, item.detalleId)}
                          />
                        </IconsContainer>
                        <StyledTimer>{getTime(item)}</StyledTimer>
                      </TimmerAndIconsContainer>
                    </StyledTitleContainer>
                    <StyledFooterCard>
                      <StyledFooter>
                        <label>Atendido por:&nbsp;</label>
                        <p>{" " + item.empleadoAtencion}</p>
                      </StyledFooter>
                    </StyledFooterCard>
                  </ColorContainer>
                ))
              : null}
            {queue.pending.length ? (
              <label style={{ color: purple_1 }}>
                <strong>A continuación</strong>
              </label>
            ) : null}
            {queue.pending.length
              ? queue.pending.map((item, index) => (
                  <PendingCardContainer key={index}>
                    <StyledTitleContainer>
                      <div>
                        <StyledTitle color={purple_1}>
                          {item.servicio}
                        </StyledTitle>
                        <StyledSubtitle color={purple_1}>
                          {" "}
                          {item.clienteNombre}
                        </StyledSubtitle>
                      </div>
                      <PendingIconContainer>
                        <AiFillPlayCircle
                          size={"2.2rem"}
                          onClick={() => changeStatus(2, item.detalleId)}
                        />
                      </PendingIconContainer>
                    </StyledTitleContainer>
                    <PendingFooterCard>
                      <StyledFooter>
                        <label>Atendido por:&nbsp;</label>
                        <p>{" " + item.empleadoAtencion}</p>
                      </StyledFooter>
                    </PendingFooterCard>
                  </PendingCardContainer>
                ))
              : ""}
            {queue.pending.length === 0 && queue.inProgress.length === 0 ? (
              <Peace
                icon="peace2"
                text="Parece que todo está calmado por aqui, no hay servicios en cola por ahora"
              />
            ) : null}
          </>
        )}
      </Main>
    </BodyContainer>
  );
}

const ColorContainer = styled(StyledColorContainer)`
  flex-direction: column;
  align-items: flex-start;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const StyledTitle = styled.h1`
  color: ${(props) => props.color || "white"};
  font-size: 0.7rem;
  font-weight: 700;
`;
const StyledSubtitle = styled.h2`
  color: ${(props) => props.color || "white"};
  font-size: 0.7rem;
  font-weight: 500;
`;

const StyledFooterCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  flex-direction: column;
  width: 100%;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  label {
    font-size: 0.7rem;
    color: white;
    font-weight: 500;
    width: auto;
  }
  p {
    font-size: 0.7rem;
    color: white;
    font-weight: 700;
  }
`;
const TimmerAndIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  color: white;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;
const StyledTimer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  font-size: 0.9rem;
  font-weight: 700;
`;

const PendingCardContainer = styled(ColorContainer)`
  background: white;
  border: 1px solid #d4d4d4;
  margin-top: 1.2rem;
`;

const PendingFooterCard = styled(StyledFooterCard)`
  label {
    color: ${purple_1};
  }
  p {
    color: ${purple_1};
  }
`;

const PendingIconContainer = styled(IconsContainer)`
  color: #937cf4;
  cursor: pointer;
`;
