import { IRequest } from "../interfaces/request";
import { IUser, IUserRequest } from "../interfaces/user";
import fetchTimeout from "../utils/fetchTimeout";
import { setHeaders, URL_REQUEST } from "./constants";

// Nueva interfaz para la respuesta del prelogin
interface IPreLoginResponse {
  data: {
    data: Array<{
      companyId: number;
      companyName: string;
      locals: Array<{
        localId: number;
        localName: string;
        employeeId: number;
      }>;
    }>;
    token_prelogin: string;
  };
  status: string;
  success: boolean;
  message: string;
}

export async function loginReq(
  email: string,
  clave: string,
): Promise<IUserRequest> {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify({
    email,
    clave,
  });

  let requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  let data: IUserRequest;
  try {
    let res = await fetchTimeout(
      URL_REQUEST + "/loginEmpleadoComandas",
      requestOptions,
      4000,
    );
    data = await res.json();
    return data;
  } catch (error) {
    return { data: null, status: "500", message: "Ocurrio un error" };
  }
}

export async function loginFinalReq(
  email: string,
  password: string,
  companyId: number,
  localId: number,
): Promise<IUserRequest> {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const preLoginToken = localStorage.getItem("preLoginToken");
  if (preLoginToken) {
    myHeaders.append("Authorization", preLoginToken);
  }

  const raw = JSON.stringify({
    email,
    password,
    companyId,
    localId,
  });

  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const res = await fetchTimeout(
      URL_REQUEST + "/login/login",
      requestOptions,
      4000,
    );

    // Verificamos el estado de la respuesta
    if (!res.ok) {
      // Si es un error 401, devolvemos un mensaje específico
      if (res.status === 401) {
        return {
          data: null,
          status: "401",
          message: "No autorizado. Por favor, verifique sus credenciales.",
        };
      }

      // Para otros errores HTTP
      throw new Error(`Error HTTP: ${res.status}`);
    }

    const data: IUserRequest = await res.json();
    return data;
  } catch (error) {
    return {
      data: null,
      status: "500",
      message:
        error instanceof Error
          ? error.message
          : "Ocurrió un error durante el inicio de sesión",
    };
  }
}

// Modificar el preLoginReq para usar la nueva interfaz
export async function preLoginReq(
  email: string,
  password: string,
): Promise<IPreLoginResponse> {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify({
    email,
    password,
  });

  let requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  let data: IPreLoginResponse;
  try {
    let res = await fetchTimeout(
      URL_REQUEST + "/login/prelogin",
      requestOptions,
      4000,
    );
    data = await res.json();
    if (data.data?.token_prelogin) {
      localStorage.setItem("preLoginToken", data.data.token_prelogin);
    }
    return data;
  } catch (error) {
    return {
      data: {
        data: [],
        token_prelogin: "",
      },
      status: "500",
      success: false,
      message: "Ocurrió un error",
    };
  }
}

export async function recoverPassReq(email: string): Promise<IRequest> {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify({
    email,
  });

  let requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let res = await fetchTimeout(
    URL_REQUEST + "/recuperarClave",
    requestOptions,
    4000,
  );
  let data: IRequest = await res.json();
  return data;
}

export const recoverDataUser = async () => {
  let requestOptions: RequestInit = {
    method: "GET",
    headers: setHeaders(),
    redirect: "follow",
  };

  let res = await fetchTimeout(
    URL_REQUEST + "/api/v1/empleado",
    requestOptions,
    4000,
  );
  if (res.status === 500 || res.status === 404) {
    throw new Error("Ocurrio un error");
  }

  let data: any = await res.json();
  let { data: user }: { data: IUser } = data;
  return user;
};
