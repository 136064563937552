export function validateEmail(email: string): string {
  if (email.length === 0) {
    return "El email es requerido";
  }
  if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)) {
    return "";
  }
  return "Formato de email invalido";
}

function validateEmailComanda(email: string): string {
  if (
    /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email) ||
    email.length === 0
  ) {
    return "";
  }
  return "Formato de email invalido";
}

function validatePhone(phone: string): string {
  if (/[9][0123456789]{8,9}/.test(phone) || phone.length === 0) {
    return "";
  }
  return "Formato de celular invalido, ej valido: 975674961";
}

function validateName(name: string): string {
  if (name.length === 0) {
    return "El nombre es un campo obligatorio";
  }

  if (name.length < 3) {
    return "El nombre debe tener al menos 3 caracteres";
  }
  return "";
}

function validateLastName(name: string): string {
  if (name.length === 0) {
    return "El apellido es un campo obligatorio";
  }

  if (name.length < 3) {
    return "El apellido debe tener al menos 3 caracteres";
  }
  return "";
}

export function validate(value: string, type: string, documentType: string) {
  switch (type) {
    case "email":
      return validateEmailComanda(value);
    case "phone":
      return validatePhone(value);
    case "name":
      return validateName(value);
    case "lastName":
      return validateLastName(value);
    case "docNumber":
      return validateDocument(value, documentType);
    default:
      return "";
  }
}

function validateDocument(value: string, documentType: string) {
  if (value.length === 0) {
    return "El Num Documento es un campo obligatorio";
  }

  if (
    (documentType === "PASAPORTE" || documentType === "CARNÉ DE EXTRANJERIA") &&
    value.length < 8
  ) {
    return "El " + documentType + " debe tener como min 8 caracteres";
  }

  if (documentType === "DNI" && /[0-9]{8}$/.test(value)) return "";
  if (documentType === "RUC" && /[0-9]{11}$/.test(value)) return "";
  if (
    (documentType === "PASAPORTE" || documentType === "CARNÉ DE EXTRANJERIA") &&
    /[a-zA-Z0-9-]{8,12}$/.test(value)
  )
    return "";
  return "Formato no valido para " + documentType;
}
