import { IService, IServiceState } from "../../interfaces/productAndServices";

type TServiceAction = { type: "SET_SERVICES_AVAIABLE"; payload: IService[] };

export const serviceReducer = (
  state: IServiceState,
  action: TServiceAction,
): IServiceState => {
  switch (action.type) {
    case "SET_SERVICES_AVAIABLE":
      return { ...state, servicesAvaiable: action.payload };
    default:
      return state;
  }
};
