import React from "react";
import paleta from "../../../assets/smiling-ice-pop.svg";

interface Props {
  setIsShowSentEmail: (any: any) => void;
}

const ModalEmailSent = (props: Props): JSX.Element => {
  return (
    <div
      style={{
        backgroundColor: "#00000061",
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: "10000",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => props.setIsShowSentEmail(false)}
    >
      <div
        style={{
          width: "300px",
          height: "300px",
          backgroundColor: "#ffffff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={paleta} style={{ height: "150px" }} alt="paleta sonriente" />
        <p style={{ width: "240px", textAlign: "center" }}>
          Tu reporte ha sido enviado con éxito a tu correo
        </p>
      </div>
    </div>
  );
};

export default ModalEmailSent;
