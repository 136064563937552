import { useCallback, useEffect, useState } from "react";
import { useLogin } from "../../hooks/login";
import { validateEmail } from "../../utils/validate";
import "./login.css";

import logo from "../../assets/ZYMPLE-SVG-WHITE.svg";
import {
  StyledInput,
  StyledLabel,
  StyledparagraphLink,
} from "../../components/styled/styled";
import styled from "styled-components";
import {
  gray_text,
  purple_1,
  gray_1,
  principalDegree,
} from "../../components/styled/constant";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FaFileDownload } from "react-icons/fa";

function Login() {
  const { recoverPass, login } = useLogin();
  const [formErrors, setFormErrors] = useState({ email: "", password: "" });
  const [recoverPassMenu, setRecoverPassMenu] = useState(false);
  const [input, setInput] = useState({ email: "", password: "" });
  const [isReadyForInstall, setIsReadyForInstall] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState({} as any);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsReadyForInstall(true);
    });
  }, []); // eslint-disable-line

  const handleInstall = useCallback(() => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        setDeferredPrompt(null);
      });
    }
  }, [deferredPrompt]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let handleErrors = formErrors;
    let { email, password } = input;
    handleErrors.email = validateEmail(email);
    password
      ? (handleErrors.password = "")
      : (handleErrors.password = "Ingrese una contraseña");
    setFormErrors({ ...handleErrors });
    if (handleErrors.email === "" && handleErrors.password === "") {
      login(email, password);
    }
  }

  async function handleRecoverPass(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let handleErrors = formErrors;
    const { email } = input;
    handleErrors.email = validateEmail(email);
    setFormErrors({ ...handleErrors });
    if (handleErrors.email === "") {
      recoverPass(email);
    }
  }

  function showPassword() {
    const password = document.getElementById("password");
    const iconOpenEye = document.getElementById("iconOpenEye");
    const iconClosedEye = document.getElementById("iconClosedEye");

    const type =
      password?.getAttribute("type") === "password" ? "text" : "password";
    if (iconOpenEye && iconClosedEye) {
      if (type === "text") {
        iconOpenEye.style.display = "inline";
        iconClosedEye.style.display = "none";
      } else {
        iconOpenEye.style.display = "none";
        iconClosedEye.style.display = "inline";
      }
    }
    password?.setAttribute("type", type);
  }

  if (!recoverPassMenu)
    return (
      <div className="App form-login">
        {
          <FaFileDownload
            style={{
              height: "1rem",
              width: "1rem",
              position: "absolute",
              opacity: 0,
            }}
            onClick={handleInstall}
          />
        }
        <LogoContainer>
          <img
            src={logo}
            alt="logo"
            className="logo-login"
            style={{
              width: "7rem",
              height: "7rem",
            }}
          />
          <label
            style={{ textAlign: "center", padding: "0 3rem", width: "100%" }}
          >
            Comandas
          </label>
        </LogoContainer>
        <StyledForm onSubmit={handleSubmit}>
          <FormBody>
            <div className="formgroup">
              <StyledLabel color={purple_1}>Correo electrónico:</StyledLabel>
              <StyledFormInput
                onFocus={() => setFormErrors({ ...formErrors, email: "" })}
                id="username"
                type="text"
                name="u"
                placeholder="Email"
                onChange={(event) =>
                  setInput({ ...input, email: event.target.value })
                }
                value={input.email}
                style={
                  formErrors.email
                    ? {
                        color: "#E80B0B",
                        borderColor: "#E80B0B",
                        width: "-webkit-fill-available",
                      }
                    : { width: "-webkit-fill-available" }
                }
              />
            </div>
            <div className="formgroup" style={{ position: "relative" }}>
              <StyledLabel color={purple_1}>Contraseña:</StyledLabel>
              <div style={{ position: "relative" }}>
                <StyledFormInput
                  onFocus={() => setFormErrors({ ...formErrors, password: "" })}
                  id="password"
                  type="password"
                  name="p"
                  placeholder="Contraseña"
                  onChange={(event) =>
                    setInput({ ...input, password: event.target.value })
                  }
                  value={input.password}
                  style={
                    formErrors.password
                      ? {
                          color: "#E80B0B",
                          borderColor: "#E80B0B",
                          width: "-webkit-fill-available",
                        }
                      : { width: "-webkit-fill-available" }
                  }
                />
                <BsEyeSlash
                  id="iconClosedEye"
                  className="iconPassword"
                  onClick={showPassword}
                />
                <BsEye
                  id="iconOpenEye"
                  className="iconPassword"
                  onClick={showPassword}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <button id="Login" type="submit" className="btn">
              Iniciar sesión
            </button>
            <StyledparagraphLink
              id="ToRecoverPass"
              onClick={() => setRecoverPassMenu(true)}
              className=""
            >
              Olvidé mi contraseña
            </StyledparagraphLink>
          </FormBody>
          {isReadyForInstall && (
            <DownloadAppContainer>
              <StyledLabel>Descarga la app </StyledLabel>
              <DownloadButton onClick={handleInstall}>Aquí</DownloadButton>
            </DownloadAppContainer>
          )}
        </StyledForm>
      </div>
    );
  return (
    <div className="App form-login">
      <LogoContainer>
        <img src={logo} alt="logo" className="logo-login" />
        <label
          style={{ textAlign: "center", padding: "0 3rem", width: "100%" }}
        >
          Comandas
        </label>
      </LogoContainer>

      <StyledForm onSubmit={handleRecoverPass}>
        <div className="formgroup">
          <StyledLabel style={{ margin: "20px 0" }}>
            Recuperando contraseña.
          </StyledLabel>
          <StyledLabel
            style={{ margin: "20px 0", color: gray_text, fontSize: ".9rem" }}
          >
            Se enviará un correo de recuperacion de contraseña.
          </StyledLabel>
          <StyledLabel>Correo electrónico:</StyledLabel>
          <StyledFormInput
            onFocus={() => setFormErrors({ ...formErrors, email: "" })}
            id="username"
            type="text"
            name="u"
            placeholder="Email"
            onChange={(event) =>
              setInput({ ...input, email: event.target.value })
            }
            value={input.email}
            style={
              formErrors.email
                ? {
                    color: "#E80B0B",
                    borderColor: "#E80B0B",
                    width: "-webkit-fill-available",
                  }
                : { width: "-webkit-fill-available" }
            }
          />
        </div>
        <button
          style={{ marginBottom: "30px" }}
          id="SendMail"
          type="submit"
          className="btn"
        >
          Enviar
        </button>
        <StyledparagraphLink
          style={{ margin: "0 auto", paddingLeft: "0", paddingRight: 0 }}
          id="ToLogin"
          onClick={() => setRecoverPassMenu(false)}
        >
          Cancelar
        </StyledparagraphLink>
      </StyledForm>
    </div>
  );
}

export default Login;

const StyledForm = styled.form`
  height: -webkit-fill-available;
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;
  padding-top: 2rem;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  width: auto;
`;

const DownloadAppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: -webkit-fill-available;
  height: auto;
  bottom: 0;
  background-color: ${gray_1};

  & > label {
    width: auto;
    color: ${purple_1};
  }
`;

const DownloadButton = styled.div`
  background: ${principalDegree};
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 999px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 0.5rem;
  cursor: pointer;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledFormInput = styled(StyledInput)`
  width: 100%;
  height: 40px;
  border: 1px solid #dadada;
  padding: 0 10px;
  font-size: clamp(0.8rem, 0.9rem, 0.9rem);
  font-weight: 500;
  color: #4d4d4d;
  transition: all 150ms ease-in-out;

  border-radius: 5px;
  min-width: 40px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    border: 1px solid #392b75;
  }

  &:focus {
    border: 2px solid #392b75;
  }
`;
