import styled from "styled-components";
import peace from "../../../assets/helado_completo_feliz.svg";
import peace2 from "../../../assets/peace_2.svg";

import { gray_4 } from "../../../components/styled/constant";
import { StyledLabel } from "../../../components/styled/styled";

type PeaceProps = {
  icon?: "peace1" | "peace2";
  text?: string;
};

export default function Peace(props: PeaceProps) {
  const { icon, text } = props;
  let image: string = "";
  if (icon === "peace1") {
    image = peace;
  }
  if (icon === "peace2") {
    image = peace2;
  }
  return (
    <StyledPeace>
      <IconContainer>
        <img src={image} alt="" />
      </IconContainer>
      <StyledLabel style={{ textAlign: "center", color: gray_4 }}>
        {text}
      </StyledLabel>
    </StyledPeace>
  );
}

const StyledPeace = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11vh 0;
  & > * {
    width: 10rem;
    height: auto;
  }
`;
