import { decodeTokenLS } from "../utils/token";

export const URL_REQUEST = "https://comanda-api-qa.zymple.io";
// export const URL_REQUEST = "http://localhost:4002";

export function setHeaders(): Headers {
  const decoded = decodeTokenLS();
  if (decoded) {
    const { token } = decoded;
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json, text/plain, */*");
    myHeaders.append("accept-language", "es-ES,es;q=0.9,en;q=0.8,pt;q=0.7");
    myHeaders.append("x-access-token", token);

    myHeaders.append("content-type", "application/json");

    return myHeaders;
  }
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  return myHeaders;
}
