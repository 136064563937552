import axios from "axios";
import {
  IServiceHistory,
  IServiceHistoryRequest,
} from "../interfaces/service-history";
import fetchTimeout from "../utils/fetchTimeout";
import { decodeTokenLS } from "../utils/token";
import { setHeaders, URL_REQUEST } from "./constants";

export async function getServiceHistory(): Promise<IServiceHistory> {
  let response: IServiceHistoryRequest;
  let employeeId;
  let localId;
  const decoded = decodeTokenLS();
  if (decoded) {
    employeeId = decoded.user.empleadoId;
    localId = decoded.user.localId;

    const raw = JSON.stringify({
      localId: localId,
      empleadoId: employeeId,
    });

    let res: any = await fetchTimeout(
      URL_REQUEST + "/api/v1/admin/comanda/mvp/listarComandasFinalizadas",
      {
        headers: setHeaders(),
        body: raw,
        method: "post",
      },
    );

    response = await res.json();
    if (response.status === "200") {
      return response.data;
    }
    throw new Error(response.message);
  }
  throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}

export async function getServiceHistoryByDates(
  startDate: any,
  endDate: any,
): Promise<any> {
  let response: any;
  // let employeeId;
  // let localId;
  const decoded = decodeTokenLS();
  if (decoded) {
    // employeeId = decoded.user.empleadoId;
    // localId = decoded.user.localId;

    // const raw = JSON.stringify({
    //     localId: localId,
    //     empleadoId: employeeId,
    // });

    // let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/comanda/mvp/resumencomandasfecha", {
    //     "headers": setHeaders(),
    //     "body": raw,
    //     "method": "post",
    // });
    const { token } = decoded;

    response = await axios
      .post(
        URL_REQUEST + "/api/v1/admin/comanda/mvp/resumencomandasfecha",
        {
          fechaInicio: startDate,
          fechaFin: endDate,
        },
        {
          headers: {
            "x-access-token": token,
          },
        },
      )
      .then((res) => res.data);

    // response = await res.json();
    if (response.status === "200") {
      return response.data;
    }
    throw new Error(response.message);
  }
  throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}

export async function getServiceHistoryByDates2(
  startDate: any,
  endDate: any,
): Promise<any> {
  let response: any;
  // let employeeId;
  // let localId;
  const decoded = decodeTokenLS();
  if (decoded) {
    // employeeId = decoded.user.empleadoId;
    // localId = decoded.user.localId;

    // const raw = JSON.stringify({
    //     localId: localId,
    //     empleadoId: employeeId,
    // });

    // let res: any = await fetchTimeout(URL_REQUEST + "/api/v1/admin/comanda/mvp/resumencomandasfecha", {
    //     "headers": setHeaders(),
    //     "body": raw,
    //     "method": "post",
    // });
    const { token } = decoded;

    response = await axios
      .post(
        URL_REQUEST + "/api/v1/admin/comanda/mvp/reportedetalladofecha",
        {
          fechaInicio: startDate,
          fechaFin: endDate,
        },
        {
          headers: {
            "x-access-token": token,
          },
        },
      )
      .then((res) => res.data);

    // response = await res.json();
    if (response.status === "200") {
      return response.data;
    }
    throw new Error(response.message);
  }
  throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}
