import { useReducer } from "react";
import { serviceContext } from "./serviceContext";
import { IService, IServiceState } from "../../interfaces/productAndServices";
import { serviceReducer } from "./serviceReducer";

interface props {
  children: JSX.Element | JSX.Element[];
}

const INITIAL_STATE: IServiceState = {
  servicesAvaiable: [
    {
      servicioId: 0,
      descripcion: "No listado",
      precio: 0,
      categoria1: "",
      categoria2: "",
      categoria3: "",
      servicioEmpleadoId: 0,
      tiempoEstimadoMinutos: 0,
    },
  ],
};

export const ServiceProvider = ({ children }: props) => {
  const [serviceState, dispatch] = useReducer(serviceReducer, INITIAL_STATE);

  const setServicesAvaiable = (services: IService[]) => {
    dispatch({
      type: "SET_SERVICES_AVAIABLE",
      payload: services,
    });
  };

  return (
    <serviceContext.Provider
      value={{
        serviceState,
        setServicesAvaiable,
      }}
    >
      {children}
    </serviceContext.Provider>
  );
};
