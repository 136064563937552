/* eslint-disable array-callback-return */
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { BsScissors } from "react-icons/bs";
import { GiSpiralBottle } from "react-icons/gi";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { productContext } from "../../../context/products/ProductsContext";
import { serviceContext } from "../../../context/services/serviceContext";
import { userContext } from "../../../context/UserContext/userContext";
import { ICart } from "../../../interfaces/cart";
import {
  IComandaDetailOrdered,
  IComandaDetailRequest,
} from "../../../interfaces/comanda/comanda";
import {
  IProduct,
  IService,
  TProductCard,
  TServiceCard,
} from "../../../interfaces/productAndServices";
import { editComanda, getComandaDetail } from "../../../request/comanda";
import {
  getProductsAvaiable,
  getServicesAvaiable,
} from "../../../request/productAndServices";
import { orderComandaDetail } from "../../../utils/orderComandas";
import { showPopup } from "../../../utils/showAlert";
import { gray_1, green_success } from "../../../components/styled/constant";
import {
  BodyContainer,
  Main,
  StyledInput,
} from "../../../components/styled/styled";
import {
  OptionsStyle,
  PlusCircleAnimated,
  PlusCircleStyle,
  WhiteBackground,
} from "../../../components/styled/styledAddButton";
import CardProducts from "../add/cards/cardProducts";
import CardServices from "../add/cards/cardServices";
import AddProductModal from "../add/modal/addProductModal";
import AddServiceModal from "../add/modal/addServiceModal";
import { showLoader } from "../../../utils/loader";
import { decodeTokenLS } from "../../../utils/token";
import { socket } from "../../../utils/socket";
import useMixpanel from "hooks/useMixpanel/useMixpanel";
import { comandaContext } from "context/ComandaContext/ComandaContext";
import { IoArrowBack } from "react-icons/io5";
import { formatToSoles } from "utils/FormatsHandler";

export default function EditComanda() {
  const { track } = useMixpanel();
  const { id } = useParams();
  const navigate = useNavigate();
  const { productState, setProductsAvaiable } = useContext(productContext);
  const { productsAvaiable } = productState;
  const { serviceState, setServicesAvaiable } = useContext(serviceContext);
  const { servicesAvaiable } = serviceState;
  const { userState } = useContext(userContext);
  const comandaState = useContext(comandaContext);
  const [isLoading, setIsLoading] = useState(true);
  const [comandaDetail, setComandaDetail] = useState<IComandaDetailOrdered>(
    {} as IComandaDetailOrdered,
  );
  const [total, setTotal] = useState(0);
  const [totalInicial, setTotalInicial] = useState(0);
  const [deleteComanda, setDeleteComanda] = useState(false);
  const [addPoS, setAddPoS] = useState<boolean>(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [aux, setAux] = useState<TServiceCard | TProductCard | undefined>(
    undefined,
  );
  const [index, setIndex] = useState<number | undefined>(undefined);
  const [cart, setCart] = useState<
    ((ICart & { initialQuantity?: number }) | undefined)[]
  >([]);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const [deletedItems, setDeletedItems] = useState<number[]>([]);
  const [addedItems, setAddedItems] = useState<any[]>([]);
  // const [initialCart, setInitialCart] = useState<any[]>([]);

  let empleadosId: number[] = [];

  const initialCharge = useCallback(async () => {
    try {
      if (id !== undefined) {
        const response: IComandaDetailRequest = await getComandaDetail(
          Number(id),
        );
        const comandasOrd: IComandaDetailOrdered = orderComandaDetail(response);

        setComandaDetail(comandasOrd);
        const handlerCart: any[] = [];

        /* Empleados de involucrados en la comandas*/
        response.data.map((service) => {
          let handler = empleadosId.find((item) => item === service.empleadoId);
          if (handler === undefined) {
            empleadosId.push(service.empleadoId);
          }
        });

        comandasOrd.services.forEach((service) => {
          const cartItem = {
            service: {
              fechaCreacion: service.fechaCreacion,
              observaciones: service.observaciones,
              precio: service.precio,
              productoId: service.productoId,
              servicio: service.servicio,
              servicioId: service.servicioId,
              descripcion: service.servicio,
              employeerId: service.empleadoId,
              nombreEstado: service.nombreEstado,
              detalleId: service.detalleId,
              empleadoAtencion: service.empleadoAtencion,
              employeeAccess: service.employeeAccess,
              comandaDetailId: service.comandaDetailId,
            },
            quantity: service.cantidad,
            initialQuantity: service.cantidad,
            employeeId: service.empleadoId,
            nombreEstado: service.nombreEstado,
            type: service.servicioId !== 0 ? "s" : "p",
            isInitial: true,
          };
          handlerCart.push(cartItem);
        });
        setCart(handlerCart);
        // setInitialCart(handlerCart);
        setTotalInicial(
          handlerCart.reduce(
            (acc, curr) => acc + curr.service.precio * curr.quantity,
            0,
          ),
        );
      }

      if (servicesAvaiable.length > 0)
        if (servicesAvaiable[0].descripcion === "No listado") {
          const services = await getServicesAvaiable();
          setServicesAvaiable(services);
        }
      if (productsAvaiable.length > 0)
        if (productsAvaiable[0].descripcion === "No listado") {
          const products = await getProductsAvaiable();
          setProductsAvaiable(products);
        }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setTimeout(() => {
        // se envia con retraso porque sino se cierra el modal
        showPopup({
          message: err.message,
          confirmButtonText: "Recargar Página",
        }).then(() => {
          window.location.reload();
        });
        console.log(err);
      }, 500);
    }
  }, [servicesAvaiable, setProductsAvaiable, setServicesAvaiable, id]); // eslint-disable-line

  useEffect(() => {
    setIsLoading(true);
    initialCharge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCharge]);

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      Swal.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    let handlerTotal = 0;
    cart.forEach((item) => {
      if (item !== undefined) {
        const myItem = item as any;
        if (myItem.type === "p") {
          handlerTotal +=
            (myItem.service?.precio || 0) * (myItem.quantity || 0);
        } else {
          handlerTotal += myItem.service?.precio || 0;
        }
        setTotal(handlerTotal);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    cart.forEach((item) => {
      if (item?.isInitial) {
        const detalleId = item.service?.detalleId;

        if (
          detalleId &&
          (item.quantity === 0 || item === undefined) &&
          !deletedItems.includes(detalleId)
        ) {
          setDeletedItems((prev) => [...prev, detalleId]);

          console.log("Item agregado a deletedItems:", {
            detalleId,
            currentDeletedItems: deletedItems,
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const handleDelete = (index: string) => {
    const itemToDelete = cart[Number(index)];

    if (itemToDelete?.isInitial && itemToDelete.service?.detalleId) {
      const detalleId = itemToDelete.service.detalleId;

      if (!deletedItems.includes(detalleId)) {
        setDeletedItems((prevItems) => [...prevItems, detalleId]);
      }

      console.log("Item eliminado:", {
        detalleId,
        deletedItems: [...deletedItems, detalleId],
      });
    } else if (!itemToDelete?.isInitial) {
      setAddedItems((prev) =>
        prev.filter((item) => {
          if (!itemToDelete) return true;
          const serviceId =
            itemToDelete.type === "s"
              ? (itemToDelete.service as IService).servicioId
              : (itemToDelete.service as IProduct).productoId;
          const itemId =
            itemToDelete.type === "s"
              ? item.PriceServiceId
              : item.LocalProductId;
          return itemId !== serviceId;
        }),
      );
    }

    const newCart = [...cart];
    newCart.splice(Number(index), 1, undefined);
    setCart(newCart);
  };

  const handlerClick = (
    index: string,
    item: TServiceCard | TProductCard,
    type: "p" | "s",
  ) => {
    return;
  };

  const handlerChangeServices = (
    index: number,
    amount: number,
    employeerId: number,
    e?: TServiceCard,
  ) => {
    const newCart = [...cart];
    const newItem = newCart[index];
    if (newItem !== undefined && e !== undefined) {
      newItem.quantity = amount;
      newItem.service = e as IService;
      newItem.employeeId = employeerId;
      setCart(newCart);
    }
  };

  const handlerChangeProducts = (
    index: number,
    amount: number,
    employeerId: number,
    e?: TProductCard,
  ) => {
    const newCart = [...cart];
    const newItem = newCart[index];

    if (newItem !== undefined) {
      newItem.quantity = amount;

      if (!newItem.isInitial) {
        setAddedItems((prev) => {
          return prev.map((item) => {
            if (
              item.LocalProductId === (newItem.service as IProduct).productoId
            ) {
              return {
                ...item,
                quantity: amount,
              };
            }
            return item;
          });
        });
      }

      if (newItem.isInitial && amount !== newItem.initialQuantity) {
      }

      newItem.service = e || newItem.service;
      newItem.employeeId = employeerId;
      setCart(newCart);
    }
  };

  const validateSaveComanda = async () => {
    if (cart.every((item) => item === undefined)) {
      const res = await showPopup({
        title: "Esta comanda será eliminada",
        message:
          "Usted ha eliminado todos los productos o servicios que contiene",
        confirmButtonText: "Salir y eliminar la comanda",
        cancelButtonText: "Seguir editando",
      });

      if (res.isConfirmed) {
        track("SaliryEliminarC");
        setDeleteComanda(true);
        handlerSubmit();
      }
      track("SeguirEditando");
    } else {
      handlerSubmit();
    }
  };
  const handleCloseModal = () => {
    setShowServiceModal(false);
    setShowProductModal(false);
    setAux(undefined);
    setIndex(undefined);
  };
  const handlerAddSoPModal = (
    e: IService | IProduct,
    type: "s" | "p",
    quantity?: number,
  ) => {
    const newItem = {
      service: e,
      quantity: quantity || 1,
      employeeId: userState.user?.empleadoId || 0,
      nombreEstado: "EN ESPERA",
      type,
      isInitial: false,
    };

    let newCart = [...cart];
    if (index !== undefined) {
      newCart.splice(index, 1, newItem);
    } else {
      newCart.push(newItem);
      const newAddedItem = {
        [type === "s" ? "PriceServiceId" : "LocalProductId"]:
          type === "s"
            ? (e as IService).servicioId
            : (e as IProduct).productoId,
        quantity: quantity || 1,
      };
      setAddedItems((prev) => [...prev, newAddedItem]);
    }
    setCart(newCart);
    setShowServiceModal(false);
    setShowProductModal(false);
    setIndex(undefined);
  };

  const handlerClickAdd = (type: "s" | "p") => {
    setAux(undefined);
    if (type === "s") {
      setShowServiceModal(true);
    } else {
      setShowProductModal(true);
    }
  };

  const backToDetails = async () => {
    if (total !== totalInicial) {
      const res = await showPopup({
        title: "Si abandonas tus cambios no se guardaran",
        confirmButtonText: "Salir",
        cancelButtonText: "Seguir editando",
      });

      if (res.isConfirmed) {
        navigate(`/comandas/detalle/${id}/1`);
      }
    } else {
      navigate(`/comandas/detalle/${id}/1`);
    }
  };

  const socketEdit = () => {
    const decoded = decodeTokenLS();
    if (decoded) {
      socket.emit("vtf_comanda_actualizacion", {
        empresaId: decoded.user.empresaId,
        empleadosId: empleadosId,
        comandaId: id,
        localId: decoded.user.localId,
      });
    }
  };

  const handlerSubmit = async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        comandaId: Number(id),
        deleteItems: deletedItems,
        comandaDetailEdit: cart
          .filter(
            (item) => item?.isInitial && item.quantity !== item.initialQuantity,
          )
          .map((item) => ({
            comandaDetailId: item?.service?.detalleId,
            // LocalProductId: item?.type === 'p'
            //   ? (item?.service as IProduct).productoId
            //   : (item?.service as IService).servicioId,
            quantity: item?.quantity,
          })),
        comandaDetailAdd: addedItems,
      };

      const response = await editComanda(requestBody);

      if (response.status === "200") {
        if (
          !cart.some((item) => item?.employeeId === userState.user?.empleadoId)
        ) {
          if (id) comandaState.deleteComanda(id);
        }
        track("GuardarCambiosC", {
          data: deleteComanda
            ? "Se ha eliminado la comanda"
            : "Se ha editado la comanda",
        });
        socketEdit();
        setTimeout(() => {
          showPopup({
            message: deleteComanda
              ? "Se ha eliminado la comanda"
              : "Se ha editado la comanda",
            confirmButtonText: "Aceptar",
            icon: "success",
          }).then(() => {
            setIsLoading(false);
            setTimeout(() => {
              navigate(`/comandas/detalle/${id}/1`);
            }, 500);
          });
        }, 500);
      } else {
        showPopup({
          message: response.message,
          confirmButtonText: "Aceptar",
          icon: "error",
        }).then(() => {
          setIsLoading(false);
        });
      }
      setDeleteComanda(false);
    } catch (err: any) {
      console.log(err);
      setTimeout(() => {
        showPopup({
          message: err || "No se pudo editar la comanda",
          icon: "error",
        }).then(() => {
          setIsLoading(false);
        });
      }, 500);
    }
  };

  // const getRequestBody = () => {
  //   const body = {
  //     comandaId: Number(id),
  //     deleteItems: deletedItems,
  //     comandaDetailEdit: cart
  //       .filter(
  //         (item) => item?.isInitial && item.quantity !== item.initialQuantity,
  //       )
  //       .map((item) => ({
  //         comandaDetailId: item?.service?.detalleId,
  //         // LocalProductId: item?.type === 'p'
  //         //   ? (item?.service as IProduct).productoId
  //         //   : (item?.service as IService).servicioId,
  //         quantity: item?.quantity,
  //       })),
  //     comandaDetailAdd: addedItems,
  //   };

  //   console.log("Body para enviar:", body);
  //   return body;
  // };

  useEffect(() => {
    return () => {
      Swal.close();
    };
  }, []);

  return (
    <>
      <AddServiceModal
        show={showServiceModal}
        handleCloseModal={handleCloseModal}
        handlerAddSoPModal={handlerAddSoPModal}
        key={"ModalAddService"}
        service={aux}
        servicesAvaiable={servicesAvaiable}
      />
      <AddProductModal
        show={showProductModal}
        handleCloseModal={handleCloseModal}
        handlerAddSoPModal={handlerAddSoPModal}
        key={"ModalAddproduct"}
        product={aux}
        productsAvailable={productsAvaiable}
      />
      <BodyContainer>
        {isLoading && <WhiteBackground style={{ zIndex: 1000 }} />}
        <MainBackground />
        {addPoS && <WhiteBackground onClick={() => setAddPoS(false)} />}

        <MainContainer>
          <BackButton onClick={() => backToDetails()}>
            <IoArrowBack size={20} />
            <span>Regresar</span>
          </BackButton>
          {userState.user?.tipoPrivacidadDatosCliente !== 3 && (
            <>
              <StyledLabel>Documento de identidad</StyledLabel>
              <StyledGroup>
                <StyledInputInverse
                  value={comandaDetail?.clientData?.docType || ""}
                  disabled
                  style={{ width: "15%" }}
                />
                <StyledInputInverse
                  value={comandaDetail?.clientData?.docNumber || ""}
                  disabled
                  style={{ width: "70%" }}
                />
              </StyledGroup>
            </>
          )}
          {/* <StyledLabel>Nombre del cliente</StyledLabel> */}
          <StyledGroup>
            <StyledInputInverse
              value={
                comandaDetail?.clientData?.name +
                  " " +
                  (userState.user?.tipoPrivacidadDatosCliente !== 3
                    ? comandaDetail?.clientData?.lastName
                    : comandaDetail?.clientData?.lastName[0] + ".") || ""
              }
              disabled
            />
          </StyledGroup>
          {cart.length > 0 &&
            cart.map((item, index) => {
              if (item !== undefined) {
                if (item.type === "p") {
                  return (
                    <CardProducts
                      handlerClick={handlerClick}
                      handlerChangeProducts={handlerChangeProducts}
                      key={index}
                      handlerDeleteProduct={handleDelete}
                      index={index.toString()}
                      product={item}
                      bgColor={gray_1}
                    ></CardProducts>
                  );
                }
                return (
                  <CardServices
                    handlerClick={handlerClick}
                    handlerChangeServices={handlerChangeServices}
                    key={index}
                    handlerDeleteService={handleDelete}
                    index={index.toString()}
                    service={item}
                    bgColor={gray_1}
                  ></CardServices>
                );
              }
              return null;
            })}
          <div
            style={{
              position: "absolute",
              bottom: "25%",
              right: "10%",
              zIndex: 300,
            }}
          >
            <PlusCircleAnimated turn={addPoS}>
              <PlusCircleStyle
                shadow={true}
                color="secundario"
                onClick={() => setAddPoS(!addPoS)}
              />
            </PlusCircleAnimated>
            {addPoS && (
              <OptionsStyle
                onClick={() => {
                  track("Añadir SoP (en edicion)");
                  setAddPoS(!addPoS);
                }}
              >
                <p
                  onClick={() => {
                    track("Añadir Servicio (en edicion)");
                    handlerClickAdd("s");
                  }}
                >
                  Servicio&nbsp;&nbsp;&nbsp;<BsScissors></BsScissors>
                </p>
                <p
                  onClick={() => {
                    track("Añadir producto (en edicion)");
                    handlerClickAdd("p");
                  }}
                >
                  Producto <GiSpiralBottle></GiSpiralBottle>
                </p>
              </OptionsStyle>
            )}
          </div>
          {/* <StyledButton
            onClick={getRequestBody}
            style={{ marginBottom: "10px" }}
          >
            Ver cambios
          </StyledButton> */}
          <StyledButton ref={saveButtonRef} onClick={validateSaveComanda}>
            Guardar cambios
          </StyledButton>
        </MainContainer>
      </BodyContainer>
      <StyledTotalContainer>
        <StyledTotal>Total a cobrar</StyledTotal>
        <StyledTotalChildContainer>
          <StyledTotal>{formatToSoles(total)}</StyledTotal>
        </StyledTotalChildContainer>
      </StyledTotalContainer>
    </>
  );
}

const MainContainer = styled(Main)`
  background-color: #fff;
  height: 100%;
`;

const MainBackground = styled.div`
  background-color: #fff;
  width: -webkit-fill-available;
  height: 100%;
  z-index: -1;
  position: fixed;
`;

const StyledLabel = styled.label`
  font-size: 0.7rem;
  font-weight: 500;
`;

const StyledInputInverse = styled(StyledInput)`
  outline: none;
`;

const StyledGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledButton = styled.button`
  border-radius: 8px;
  min-height: 50px;
  text-align: center;
  white-space: nowrap;
  font-size: clamp(0.6rem, 0.88rem, 0.9rem);
  border: none;
  background: #937cf4;
  color: #fff;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  padding: 0 35px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 64px;
  color: white;
  bottom: 0px;
  background-color: ${green_success};
  width: 100%;
  z-index: 275;
`;

const StyledTotal = styled.label`
  color: white;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
`;

const StyledTotalChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  color: #45348e;
  cursor: pointer;
  font-size: clamp(0.8rem, 1rem + 1vw, 0.9rem);
  transition: opacity 0.2s ease;
  text-decoration: underline;
  font-family: Mulish, sans-serif;
  &:hover {
    opacity: 0.9;
  }

  svg {
    color: #45348e;
  }
`;
