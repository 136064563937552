import { createContext } from "react";
import { IComandaList, IComandaState } from "../../interfaces/comanda/comanda";

export type ComandaContextProps = {
  comandaState: IComandaState;
  setComandas: (comandas: IComandaList[]) => void;
  deleteComanda: (id: string) => void;
};

export const comandaContext = createContext<ComandaContextProps>(
  {} as ComandaContextProps,
);
