import Swal from "sweetalert2";
import spinner from "../assets/alert-icons/Spinner-1s-191px.svg";

export function showLoader(text: string = "Espere un momento por favor") {
  return Swal.fire({
    title: text,
    html: `<img  src='${spinner}'/>`,
    showCancelButton: false,
    showConfirmButton: false,
    customClass: {
      title: "title-loader",
    },
    width: "431px",
  });
}

export function closeLoader() {
  return Swal.close();
}
